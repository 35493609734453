import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Store } from 'antd/lib/form/interface';
import { Layout, Typography, Table, Input, Steps } from 'antd';
import { CriteriaForm, AuthButton, AuthButtonAuthorities } from '../../core/CoreForm';
import { ContentEx as Content, FunctionTitle, CommonMessage } from '../../core/CorePageContents';
import styles from './ProjectSelect.module.css';
import * as Module from '../../../modules/prime/projectSelect/projectSelectModule';
import * as Model from '../../../modules/prime/projectSelect/projectSelectModel';
import { initMessages } from '../../../modules/core/commonMessageModule';
import { reflectProjectModalState, reflectPartnerCompanyModalState } from '../../../modules/prime/partner-companies/partner-companiesModule';
import { setCriteria as setPartnerCompanyCriteria, initState, storedDataCriteria as partnerCompanyStoredDataCriteria, reflectModalSelectValue, reflectModalSelectProject, setCurrentPage } from '../../../modules/prime/partnerCompanySelect/partnerCompanySelectModule';
import { Criteria as PartnerCompanyCriteria } from '../../../modules/prime/partnerCompanySelect/partnerCompanySelectModel';
import * as CONSTANTS from '../../../constants/constants';
import { initApiResult, storedApiResult } from '../../../modules/core/bffApiModule';
const { Title } = Typography;

interface titleInterface {
  title: string;
}

export const ProjectSelect = (props: titleInterface) => {

  const dispatch = useDispatch();
  const apiResult = useSelector(storedApiResult);
  let dataList = useSelector(Module.storedDataList);
  let dataCriteria = useSelector(Module.storedDataCriteria);
  let totalCnt = useSelector(Module.storedDataTotal);
  let currentPage = useSelector(Module.storedDataCurrentPage);
  let nextPageTokenList = useSelector(Module.storedNextPageTokenList);
  let partnerCompanyDataCriteria = useSelector(partnerCompanyStoredDataCriteria);
  const { Step } = Steps;

  useEffect(() => {
    // API処理結果を初期化します。
    dispatch(initApiResult());
    dispatch(Module.getDataListAsync(dataCriteria));
  },
    [dataCriteria]
  );

  // プロジェクト検索モーダル検索ボタン押下時イベント
  const searchProjectDataList = (value: Store) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    let criteria: Model.Criteria = {
      projectName: value.projectName,
      nextPageToken: "",
      limit: dataCriteria.limit
    }
    dispatch(Module.setCurrentPage(1));
    dispatch(Module.setCriteria(criteria));
  }

  //　ページング選択時イベント
  const handleTableChange = (pagination: any) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    let criteria: Model.Criteria = {
      projectName: dataCriteria.projectName,
      nextPageToken: typeof nextPageTokenList[pagination.current] === "undefined" ? "" : nextPageTokenList[pagination.current],
      limit: pagination.pageSize
    }
    dispatch(Module.setCurrentPage(pagination.current));
    dispatch(Module.setCriteria(criteria));
  }

  // 検索が終了した時の処理（次ページが取得できなかった場合、総件数0件で返却されるので対応）
  if (CONSTANTS.API_STATUS_INITIAL !== apiResult.status
    && CONSTANTS.REQUEST_METHOD_GET === apiResult.requestMethod
    && CONSTANTS.URL_CMN_PRIME_PROJECTS === apiResult.url) {

    // 2ページ目以降を表示していて、表示するデータが空の場合、再取得する
    if (currentPage > 1 && dataList.length === 0) {

      // API結果情報を初期化します。
      dispatch(initApiResult());

      // 一つ前のページを取得する
      let toPage = currentPage - 1;

      // 共通メッセージ情報を初期化します。
      dispatch(initMessages());
      let criteria: Model.Criteria = {
        projectName: dataCriteria.projectName,
        nextPageToken: typeof nextPageTokenList[toPage] === "undefined" ? "" : nextPageTokenList[toPage],
        limit: dataCriteria.limit
      }
      dispatch(Module.setCurrentPage(toPage));
      dispatch(Module.setCriteria(criteria));
    }
  }

  //　行選択時イベント
  const selectRow = (record: Model.DataRow) => {
    // 共通メッセージ情報を初期化します。
    dispatch(initMessages());
    // 協力会社検索モーダル情報を初期化
    dispatch(setCurrentPage(1));
    dispatch(reflectModalSelectValue(""));
    dispatch(reflectModalSelectProject(record.projectId));
    let criteria: PartnerCompanyCriteria = {
      projectId: record.projectId,
      companyName: "",
      nextPageToken: "",
      limit: 10,
    }
    // 選択したプロジェクトのIDをPartnerCompanyCriteriaに保持する。
    dispatch(setPartnerCompanyCriteria(criteria));
    // プロジェクト検索モーダル表示を消す
    dispatch(reflectProjectModalState(false));
    // 協力会社検索モーダルを表示する
    dispatch(reflectPartnerCompanyModalState(true));
  }

  const columns = [
    {
      title: 'プロジェクト名',
      dataIndex: 'projectName',
      align: 'center' as 'center'
    },
  ]

  // プロジェクト検索モーダル検索フォームを描画します。
  const SearchProjectCriteria = () => {
    return (
      <CriteriaForm layout="inline" onFinish={searchProjectDataList} initialValues={{ "projectName": dataCriteria.projectName }}>
        <div className={styles.criteriaSearchField}>
          <CriteriaForm.Item name="projectName">
            <Input type="text" placeholder="プロジェクト名称" maxLength={CONSTANTS.PROJECT_NAME_MAX_LENGTH} />
          </CriteriaForm.Item>
          <AuthButton name={"検索"} htmlType="submit" shape="round" />
        </div>
      </CriteriaForm>
    )
  }

  // プロジェクト検索モーダル検索フォームのステップを描画します。
  const SearchProjectSteps = () => {
    return (
      <Steps size="small" current={0} className={styles.step}>
        <Step title="プロジェクト選択" />
        <Step title="協力会社選択" />
      </Steps>
    )
  }

  // ページングボタンを描画します。
  const pageItemRender = (page: any, type: string, originalElement: any) => {
    if (type === 'prev') {
      return <AuthButton name='＜' size='small' />;
    }
    if (type === 'next') {
      return <AuthButton name='＞' size='small' />;
    }
    return originalElement
  }

  return (
    <Layout className={styles.layout}>
      <Content>
        <FunctionTitle title={props.title} />
        <SearchProjectCriteria />
        <SearchProjectSteps />
        <CommonMessage />
        {totalCnt > 0 ?
          <>
            <span>追加したい協力会社が参加しているプロジェクトを検索し、選択してください。</span>
            <Table
              columns={columns}
              dataSource={dataList}
              size='small'
              rowKey="projectId"
              scroll={{ x: 500, y: 190 }}
              pagination={{
                total: totalCnt  //総件数
                , defaultCurrent: 1 //
                , current: currentPage
                , pageSize: dataCriteria.limit //ページサイズを入れる
                , pageSizeOptions: ['5', '10', '20', '50', '100']
                , showSizeChanger: true
                , showTotal: (total, range) => `${total}件中、${range[0]}件目から${range[1]}件目を表示`
                , itemRender: pageItemRender
                , locale: { items_per_page: '件/ページ' }
              }}
              onChange={(pagination) => handleTableChange(pagination)}
              onRow={(record, rowIndex) => {
                return {
                  onClick: event => { selectRow(record) }
                };
              }}
            />
          </> : <></>
        }
      </Content>
    </Layout>
  );
}